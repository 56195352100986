export const WWW_URL = "https://judocu.ch";
export const SUPPORT_URL = {
  type: "link",
  link: "https://shop.judocu.ch",
};

export const PROD_HOSTNAME = "app.judocu.ch";
export const DEV_HOSTNAME = "app.judocu.ch";

export const PROD_URL = `https://${PROD_HOSTNAME}`;
export const DEV_URL = `https://${DEV_HOSTNAME}`;

export const LOGO_SRC = "/img/logo/logo.png";
export const SMALL_LOGO_SRC = "/img/logo/logo-small.png";

export const APP_TITLE = "Judocu";

export const companyFeatures = {
  isWhiteLabel: true,
  enablePetalRoutes: false,
  enableSecondaryApps: false,
  workspace: {
    singleWorkspace: false,
  },
  auth: {
    enableSignUp: true,
    enableGoogleAuth: false,
    enableForgotPassword: true,
  },
  settings: {
    enableSettingsPage: true,
    canUpgradePlans: true,
    canLeaveWorkspace: true,
  },
  app: {
    canSeeOnboarding: true,
    isTrialExists: true,
    canInvite: true,
    canCreateOrg: true,
    showDocumentation: true,
    guests: {
      canSeeNotifications: true,
      enableDocumentViewer: true,
      enableDocumentActions: true,
      enableLookUpTab: true,
    },
  },
};

export const companySettings: {
  defaultAIModel: "gpt-4o-mini" | "gpt-4o";
  defaultMultiDocChatModel: "gpt-4o-mini-16k" | "gpt-4o";
} = {
  defaultAIModel: "gpt-4o-mini",
  defaultMultiDocChatModel: "gpt-4o-mini-16k",
};
