/* eslint-disable import/prefer-default-export */
import { Box, styled } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: "0.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  flex: 1,
  overflow: "hidden",
  "& #main-content": {
    overflow: "auto !important",
    display: "flex",
    alignItems: "center",
    width: "100%",
    flex: 1,
    "&::-webkit-scrollbar": {
      backgroundColor: theme.background.light,
      border: `1px solid ${theme.grey.light}`,
      borderleft: "none",
    },
    "& .table-container": {
      height: "100%",
      flex: 1,
      background: theme.background.light,
      "& .table": {
        width: "100%",
        background: "#fff",
        border: `none`,
        fontSize: "14px",
        textAlign: "left",
        padding: 0,
        margin: 0,
        "& .head": {
          position: "sticky",
          top: 0,
          zIndex: 2,
          "& .header-container": {
            display: "flex",
            alignItems: "center",
          },
          "& .header": {
            position: "relative",
            background: theme.background.light,
            width: "400px",
            height: "55.5px",
            display: "flex",
            alignItems: "center",
            padding: "0.5rem 0.8rem",
            border: `1px solid ${theme.grey.light}`,
            borderLeft: `none`,
            justifyContent: "space-between",
            "&.documents": {
              minWidth: "500px",
              flex: 1,
              gap: "1rem",
              "& .document-filters": {
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                "& .document-checkbox": {
                  padding: "0.2rem",
                },
              },
              "& .secondary-container": {
                display: "flex",
                alignItems: "center",
                gap: 2,
                "& .page-container": {
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                },
              },
            },
          },
          "& .header:first-of-type": {
            borderLeft: `1px solid ${theme.grey.light}`,
            left: 0,
            zIndex: 3,
            position: "sticky",
          },
          "& .header:not(:first-of-type)": {
            background: theme.background.light,
            "&:not(.disabled):not(.dragging):hover": {
              background: `#F1F4F8`,
              "& .actions-container": {
                display: "flex",
              },
            },
            "&.dragging": {
              background: `${theme.document.dragging.light}`,
              border: `1px solid ${theme.document.dragging.main}`,
            },
            "& .column-name": {
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflowWrap: "anywhere",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
            "& .actions-container": {
              display: "none",
              alignItems: "center",
              background: theme.background.light,
              border: `1px solid ${theme.grey.light}`,
              padding: "0.1rem 0.5rem",
              borderRadius: "4px",
              gap: "0.5rem",
              position: "absolute",
              right: "10px",
              "& .language-shortcut": {
                color: theme.background.light,
                fontSize: "12px",
                background: theme.palette.text.secondary,
                borderRadius: "4px",
                padding: "0 3px",
                whiteSpace: "nowrap",
              },
              // triggers on onboarding
              "&.onboarding": {
                display: "flex",
              },
            },
          },
        },
        "& .table-body": {
          "& .table-row": {
            display: "flex",
            "& .cell-container": {
              border: `1px solid ${theme.grey.light}`,
              borderTop: "none",
              verticalAlign: "center",
              width: "400px",
              minWidth: "400px",
              "& p": {
                margin: 0,
              },
              "& ul,& ol": {
                paddingLeft: "1rem",
                margin: "0.5rem 0",
              },
              "& .status": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                "& .MuiCircularProgress-root": {
                  width: "20px",
                  height: "20px",
                },
                "& .error-message": {
                  color: theme.red.main,
                },
              },
              "&:not(:has(.main-cell))": {
                padding: "0.5rem 0.8rem",
                position: "relative",
                borderLeft: "none",
                "&:hover": {
                  "& .get-answer-box": {
                    display: "flex",
                  },
                },
                "&.dragging": {
                  transition: "all 0.3s",
                  filter: "blur(2px)",
                },
                "& .get-answer-box": {
                  display: "none",
                  position: "absolute",
                  bottom: "0.5rem",
                  right: "1rem",
                  columnGap: "0.5rem",
                  "& .edit-button, .copy-button": {
                    background: theme.grey.light,
                  },
                },
              },
              "&:first-of-type": {
                width: "100%",
                minWidth: "500px",
                position: "sticky",
                left: 0,
                zIndex: 1,
                background: theme.background.light,
                "& .main-cell": {
                  height: "100%",
                  width: "100%",
                },
              },
            },
          },
        },
      },
    },
    "& .add-container": {
      width: "300px",
      height: "100%",
      position: "sticky",
      top: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `2px dashed ${theme.palette.primary.main}`,
      background: theme.background.light,
      padding: "1rem",
    },
  },
  "& .MuiButtonBase-root": {
    whiteSpace: "nowrap",
  },
  "& .no-documents-message": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    "& img": {
      width: "250px",
      [theme.breakpoints.down("lg")]: {
        width: "200px",
      },
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
    },
  },
}));
