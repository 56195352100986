/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { persistor, store } from "store/index";
import App from "App";
import { MathJaxContext } from "better-react-mathjax";
import { DEV_URL, PROD_HOSTNAME, PROD_URL } from "company-config";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 120000,
      refetchOnReconnect: true,
    },
  },
});

const MathJaxConfig = {
  tex: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    packages: { "[+]": ["upgreek"] },
  },
  svg: {
    fontCache: "global",
  },
  loader: { load: ["[tex]/upgreek"] },
};

// If running on localhost, use staging server
axios.defaults.baseURL = DEV_URL;
if (window.location.hostname === PROD_HOSTNAME) {
  axios.defaults.baseURL = PROD_URL;
}
axios.defaults.headers.post["Content-Type"] = "application/json";

// Override baseURL using localStorage.setItem("baseURL", "url");
if (
  localStorage.getItem("baseURL") !== null &&
  localStorage.getItem("baseURL") !== axios.defaults.baseURL
) {
  if (
    // eslint-disable-next-line no-alert
    window.confirm(
      `Press OK to continue using baseURL ${localStorage.getItem(
        "baseURL"
      )}? Press Cancel to use ${axios.defaults.baseURL}.`
    )
  ) {
    axios.defaults.baseURL = localStorage.getItem("baseURL") || undefined;
    console.log(`Using `);
  } else {
    localStorage.removeItem("baseURL");
  }
}

const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <QueryClientProvider client={client}>
      <PersistGate persistor={persistor}>
        <MathJaxContext config={MathJaxConfig}>
          <App />
        </MathJaxContext>
      </PersistGate>
    </QueryClientProvider>
  </Provider>
);
