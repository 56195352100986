import { authService } from "api";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { cleanup } from "store/features/session/slice";
import { goToLogin } from "utils/accountsUtils";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";

const Logout: React.FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { logAction } = useTelemetry();
  const urlSearchParams = new URLSearchParams(search);
  const service = urlSearchParams.get("service") || "";

  useEffect(() => {
    authService.logout().then(() => {
      goToLogin(service);
      localStorage.clear();
      dispatch(cleanup());
      logAction(telemetryAction.logout, {});
    });
  }, []);

  return null;
};

export default Logout;
