/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from "react";
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useSelector } from "react-redux";
import { stringAvatar } from "utils/userAvatar";
import routePaths from "routes/routePaths";
import { useLocation, useNavigate } from "react-router-dom";
import { SUPPORT_URL, companyFeatures } from "company-config";
import ReferFriendDialog from "components/Dialogs/ReferFriendDialog";
import { isAdmin } from "models/components/Permissions.models";
import { useUsers } from "api/userService";

const ButtonContainer = styled(ListItemButton)(({ theme }) => ({
  flexGrow: 0,
  padding: theme.spacing(1),
  borderRadius: "4px",
  width: "100%",
  "&.Mui-selected": {
    background: theme.grey.light,
  },
  "& .MuiListItemIcon-root": {
    minWidth: "0",
    marginRight: "0.5rem",
  },
  "& .MuiListItemText-root": {
    marginRight: "0.5rem",
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& .MuiAvatar-root": {
    borderRadius: "4px",
  },
}));

const ProfileSelector: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [openReferFriendDialog, setOpenReferFriendDialog] =
    useState<boolean>(false);
  const refSelect = useRef(null);

  return (
    <>
      <ButtonContainer
        ref={refSelect}
        selected={Boolean(menuAnchor)}
        onClick={(event) => {
          event.preventDefault();
          setMenuAnchor(refSelect.current);
        }}
      >
        <ListItemAvatar>
          {user?.meta?.avatar ? (
            <Avatar alt={user?.name} src={user?.meta?.avatar} />
          ) : (
            <Avatar {...stringAvatar(user?.name || "")} />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography fontWeight={500} variant="body2">
              {user?.name}
            </Typography>
          }
          secondary={user?.email}
        />
      </ButtonContainer>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => {
          setMenuAnchor(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!pathname.includes("workspace") &&
          companyFeatures.settings.enableSettingsPage &&
          isAdmin(currentUserRole) && (
            <MenuItem
              onClick={() => {
                navigate(routePaths.profile);
                setMenuAnchor(null);
              }}
            >
              <ListItemIcon className="menu-icon">
                <PermIdentityOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Profile</Typography>
              </ListItemText>
            </MenuItem>
          )}
        {!companyFeatures.isWhiteLabel && (
          <MenuItem
            onClick={() => {
              setOpenReferFriendDialog(true);
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon className="menu-icon">
              <PersonAddOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Refer a friend</Typography>
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            if (SUPPORT_URL.type === "link") {
              window.open(SUPPORT_URL.link, "_blank");
            }
            if (SUPPORT_URL.type === "email") {
              window.location.href = SUPPORT_URL.link;
            }
            setMenuAnchor(null);
          }}
        >
          <ListItemIcon className="menu-icon">
            <ContactMailOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Contact Us</Typography>
          </ListItemText>
        </MenuItem>
        {companyFeatures.app.showDocumentation && (
          <MenuItem
            onClick={() => {
              window.open("https://shop.judocu.ch/faq", "_blank");
              setMenuAnchor(null);
            }}
          >
            <ListItemIcon className="menu-icon">
              <HistoryEduOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">Documentation</Typography>
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            navigate(`${routePaths.logout}?service=cite`);
            setMenuAnchor(null);
          }}
        >
          <ListItemIcon className="menu-icon">
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Log out</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
      {openReferFriendDialog && (
        <ReferFriendDialog setOpen={setOpenReferFriendDialog} />
      )}
    </>
  );
};

export default ProfileSelector;
