import React, { useMemo } from "react";
import { DocumentReadResponse } from "models/api/response.types";
import {
  Box,
  Checkbox,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import detexify, { applyDocumentDetexification } from "utils/detexify";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { bibtexEntry2html } from "utils/bibtex";
import clsx from "clsx";
import { useSelector } from "react-redux";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useUsers } from "api/userService";
import { isGuest } from "models/components/Permissions.models";
import { companyFeatures } from "company-config";
import {
  getDocumentStatus,
  isTable as isTableType,
  isStub as isStubType,
} from "utils/documentStatus";
import { setAuthorsData } from "../../../utils/documentListHelpers";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "0.5rem 0.8rem",
  height: "100%",
  "&:not(.selected):hover": {
    background: theme.document.hover.main,
  },
  "&.selected": {
    background: theme.document.selected.main,
    "&:hover": {
      background: theme.document.selected.secondary,
    },
  },
  "&:not(.disabled)": {
    cursor: "pointer",
  },
  "& .document-checkbox": {
    padding: "0.2rem",
    marginRight: "0.3rem",
    height: "fit-content",
    "&:not(.Mui-checked)": {
      color: "rgba(0, 0, 0, 0.12)",
    },
  },
  "& .doc-type-icon": {
    marginTop: "0.15rem",
    marginRight: "0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",
  },
  "& .main-content": {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    flex: 1,
    "& .document-header": {
      display: "flex",
      width: "fit-content",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: "0.5rem",
      "& .title": {
        fontSize: "16px",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        cursor: "default",
        "&.document": {
          color: theme.palette.secondary.main,
        },
        "&.table-source": {
          color: "#52796f",
        },
        "&.active": {
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
      "& .warning-icon": {
        marginTop: "0.1rem",
      },
    },
    "& .authors": {
      fontSize: "14px",
      WebkitLineClamp: "1",
      color: theme.document.compact.authors,
    },
    "& .description": {
      fontSize: "14px",
      WebkitLineClamp: "5",
      color: theme.palette.text.secondary,
    },
    "& .compact-document-data": {
      WebkitLineClamp: "1",
      fontSize: "14px",
      "& .journal": {
        color: theme.document.compact.journal,
      },
      "& .year": {
        color: theme.document.compact.year,
      },
    },
    "& .title,& .authors,& .compact-document-data,& .description": {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflowWrap: "anywhere",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const AiTableDocument: React.FC<{
  readOnly: boolean;
  document: DocumentReadResponse;
  setTableDocumentToUpdate: (doc: DocumentReadResponse) => void;
  selectedDocuments: DocumentReadResponse[];
  setSelectedDocuments: (documents: DocumentReadResponse[]) => void;
}> = ({
  readOnly,
  document: docData,
  selectedDocuments,
  setSelectedDocuments,
  setTableDocumentToUpdate,
}) => {
  const theme = useTheme();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { logAction } = useTelemetry();
  const document = applyDocumentDetexification(docData);
  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const isSelected = selectedDocuments?.some((doc) => doc.id === document.id);
  const isDocViewerEnabled =
    !isGuest(currentUserRole) ||
    companyFeatures.app.guests.enableDocumentViewer;
  const isContextLimitExceeded = docData?.meta?.text_character_count > 1000000;

  const authors = setAuthorsData(
    document?.detexiFiedMeta?.author || document?.meta?.author
  );

  const { isStub, isTable, defaultTitle, defaultDescription } = useMemo(() => {
    return {
      isStub: isStubType(document),
      isTable: isTableType(document),
      defaultTitle:
        document?.ui?.show_file_name?.includes(user?.id) && document.filename
          ? document.filename
          : document?.detexiFiedMeta?.title ||
            document?.meta?.title ||
            document.filename ||
            "",
      defaultDescription:
        document?.detexiFiedMeta?.abstract || document?.meta?.abstract || "",
    };
  }, [document]);

  const openDocumentViewer = (method: string) => {
    const newPageUrl = `/document/${document.id}/view?organizationId=${document.organization_id}`;
    logAction(telemetryAction.open_document_viewer, {
      document_id: document.id,
      method,
    });
    window.open(newPageUrl, "_blank");
  };

  const documentStatus = getDocumentStatus(document);

  const referenceInfo = useMemo(() => {
    const formatted = bibtexEntry2html(
      document.detexiFiedMeta
        ? {
            ...document.detexiFiedMeta,
            author: Array.isArray(document?.detexiFiedMeta?.author)
              ? document.detexiFiedMeta.author.join(" and ")
              : `${document?.detexiFiedMeta?.author}`,
          }
        : {
            ...document.meta,
            author: Array.isArray(document?.meta?.author)
              ? document.meta.author.join(" and ")
              : `${document?.meta?.author}`,
          },
      true
    );
    if (
      formatted.remainder.includes("()") ||
      formatted.remainder.includes("undefined")
    ) {
      return "";
    }
    return formatted.remainder;
  }, [document]);

  return (
    <Wrapper
      className={clsx({
        selected: isSelected,
        disabled: readOnly,
      })}
      onClick={() => {
        if (!readOnly) {
          if (selectedDocuments.some((doc) => doc.id === document.id)) {
            const filteredDocs = selectedDocuments.filter(
              (doc) => doc.id !== document.id
            );
            setSelectedDocuments(filteredDocs);
          } else {
            setSelectedDocuments([...selectedDocuments, document]);
          }
        }
      }}
    >
      {!readOnly && (
        <Checkbox
          className="document-checkbox"
          color="primary"
          checked={isSelected}
          onChange={(e) => {
            e.stopPropagation();
            if (isSelected) {
              setSelectedDocuments(
                selectedDocuments.filter((doc) => doc.id !== document.id)
              );
            } else {
              setSelectedDocuments([...selectedDocuments, document]);
            }
          }}
          size="small"
        />
      )}
      <Box className="doc-type-icon">
        {isTable ? (
          <TableChartOutlinedIcon fontSize="small" color="action" />
        ) : (
          <AttachFileIcon fontSize="small" color="action" />
        )}
        {isTable && !defaultDescription && (
          <Tooltip
            enterDelay={500}
            placement="bottom"
            title="This table is missing descriptions. 
                The AI relies on the description to understand the nature of the information within the table. 
                Please provide a detailed description if possible."
          >
            <AnnouncementOutlinedIcon
              style={{
                color: theme.palette.error.dark,
                width: "1.25rem",
                height: "1.25rem",
              }}
            />
          </Tooltip>
        )}
      </Box>
      <Box className="main-content">
        <Box className="document-header">
          <Typography
            className={clsx("title", {
              document: !isStub && !isTable,
              active: isDocViewerEnabled || isTable,
              "table-source": isTable,
            })}
            onClick={(e) => {
              e.stopPropagation();
              if (documentStatus.can_be_viewed) {
                openDocumentViewer("ai_table_title_click");
              } else if (isTable) {
                setTableDocumentToUpdate(document);
              }
            }}
          >
            {defaultTitle}
          </Typography>
          {(document.status.embeddings === 3 ||
            !document.status.embeddings ||
            isContextLimitExceeded) &&
            !readOnly && (
              <Tooltip
                placement="top"
                enterDelay={500}
                title={
                  isContextLimitExceeded
                    ? "Your context size exceeds our recommended limit of 1.000.000 characters, which may result in degraded AI performance."
                    : "Text analysis for this document encountered difficulties, which may affect the AI's functionality."
                }
              >
                <WarningAmberOutlinedIcon
                  className="warning-icon"
                  color="warning"
                  fontSize="small"
                />
              </Tooltip>
            )}
        </Box>
        <Typography className="authors">
          {authors.length > 0 ? authors.join(", ") : "No authors"}
        </Typography>
        {referenceInfo && !isTable && (
          <Box
            className="compact-document-data"
            dangerouslySetInnerHTML={{
              __html: detexify(referenceInfo, false),
            }}
          />
        )}
        {isTable && defaultDescription && (
          <Typography className="description">{defaultDescription}</Typography>
        )}
      </Box>
    </Wrapper>
  );
};

export default AiTableDocument;
