import { useCallback, useMemo } from "react";
import axios, {
  AxiosError,
  AxiosPromise,
  CancelToken as CancelTokenType,
} from "axios";
import {
  AccessTokenResponse,
  SuccessResponse,
  User,
} from "models/api/response.types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import handleAxiosError from "utils/handleAxiosAlert";
import { IUserDataTelemetry } from "utils/useTelemetry";

const fetchOrganizationUsers = (
  organizationId: number,
  options?: { cancelToken?: CancelTokenType }
): AxiosPromise<User[]> =>
  axios.get(`/api/organization/${organizationId}/user/list`, {
    cancelToken: options?.cancelToken,
  });

const sendUserTelemetryData = (
  data: IUserDataTelemetry[]
): AxiosPromise<SuccessResponse> => {
  return axios.post(`/api/telemetry`, data);
};

const deleteUser = (
  orgId: number,
  user_id: number
): Promise<SuccessResponse> => {
  return axios.post(`/api/organization/${orgId}/user/remove`, { user_id });
};

const updateUserRole = (
  orgId: number,
  user_id: number,
  role: string
): Promise<SuccessResponse> => {
  return axios.post(`api/organization/${orgId}/user/set_role`, {
    user_id,
    role,
  });
};

const getUserTelemetry = (
  userId: number
): AxiosPromise<IUserDataTelemetry[]> => {
  return axios.get(`/api/telemetry/list?user_id=${userId}`);
};

const logAdminAsUser = (user_id: number): AxiosPromise<AccessTokenResponse> => {
  return axios.post(`/api/admin/login_as_user`, { user_id });
};

const userService = {
  fetchOrganizationUsers,
  sendUserTelemetryData,
  getUserTelemetry,
  logAdminAsUser,
  deleteUser,
  updateUserRole,
};

export default userService;

// Used to add a cancel property to Promise to appease Typescript
// See https://github.com/tannerlinsley/react-query/issues/1265
interface ExtendedPromise<T> extends Promise<T> {
  cancel?: () => void;
}

interface UseUsersResult {
  users: User[] | undefined;
  usersIsLoading: boolean;
  usersQueryKey: string[];
  getCachedUserById: (
    id: number
  ) => [item: Readonly<User> | undefined, index: Readonly<number>];
  upsertCachedUser: (newUser: User) => User | undefined;
  removeCachedUser: (userId: number) => User | undefined;
  deleteUserMutation: any;
  updateUserRoleMutation: any;
}

export const useUsers = (
  organizationId: number | undefined
): UseUsersResult => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const queryClient = useQueryClient();
  const usersQueryKey = [`organization/${organizationId}/user/list`];
  const { data: users, isLoading: usersIsLoading } = useQuery<
    unknown,
    unknown,
    User[],
    any
  >(
    usersQueryKey,
    (): ExtendedPromise<User[]> => {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promise: any = userService.fetchOrganizationUsers(
        organizationId as number,
        {
          cancelToken: source.token,
        }
      );
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise.then(
        ({ data: responseData }: { data: User[] }) => responseData
      );
    },
    {
      enabled: !!organizationId,
      placeholderData: undefined,
      onError: (err) =>
        handleAxiosError(err as AxiosError, dispatch, { user: currentUser }),
    }
  );

  const userIndexById = useMemo(
    () =>
      users?.reduce((lut: { [id: number]: number }, user, index) => {
        // eslint-disable-next-line no-param-reassign
        lut[user.id] = index;
        return lut;
      }, {}),
    [users]
  );

  const getCachedUserById: (
    id: number
  ) => [item: User | undefined, index: number] = useCallback(
    (id: number) =>
      users && userIndexById && id in userIndexById
        ? [users[userIndexById[id]], userIndexById[id]]
        : [undefined, -1],
    [userIndexById]
  );

  const upsertCachedUser = (newUser: User): User | undefined => {
    const [currentOrgUser, index] = getCachedUserById(newUser.id);
    if (users) {
      const newUsers = [...users];
      if (index > -1) {
        newUsers.splice(index, 1, newUser);
      } else {
        newUsers.unshift(newUser);
      }
      queryClient.setQueryData<User[]>(usersQueryKey, newUsers);
    } else {
      queryClient.setQueryData<User[]>(usersQueryKey, [newUser]);
    }
    return currentOrgUser;
  };

  const removeCachedUser = (userId: number): User | undefined => {
    const [currentOrgUser, index] = getCachedUserById(userId);
    if (users && currentOrgUser) {
      const newUsers = [...users];
      newUsers.splice(index, 1);
      queryClient.setQueryData<User[]>(usersQueryKey, newUsers);
      return currentOrgUser;
    }
    return undefined;
  };

  const updateUserRoleMutation = useMutation(
    ({ userId, role }: { userId: number; role: string }) =>
      userService.updateUserRole(organizationId || -1, userId, role),
    {
      retry: false,
      onMutate: async ({ userId, role }) => {
        const [user] = getCachedUserById(userId);
        if (user) {
          const newUser = { ...user };
          newUser.role = role;
          return upsertCachedUser(newUser);
        }
        return undefined;
      },
      onSettled: () => {
        queryClient.invalidateQueries(usersQueryKey);
      },
    }
  );

  const deleteUserMutation = useMutation(
    (userId: number) => userService.deleteUser(organizationId || -1, userId),
    {
      retry: false,
      onMutate: async (userId: number) => {
        const removedUser = removeCachedUser(userId);
        return { removedUser };
      },
      onSettled: (_response, error) => {
        if (error) {
          // on error we roll-back the optimistic update by restoring context.previousDocument
          handleAxiosError(error as AxiosError, dispatch);
          queryClient.invalidateQueries(usersQueryKey);
        }
      },
    }
  );

  return {
    users,
    usersIsLoading,
    usersQueryKey,
    getCachedUserById,
    upsertCachedUser,
    removeCachedUser,
    deleteUserMutation,
    updateUserRoleMutation,
  };
};
