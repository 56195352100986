/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-else-return */
import React, {
  CSSProperties,
  useState,
  useRef,
  useEffect,
  useContext,
  useMemo,
} from "react";
import {
  DocumentCollection,
  DocumentReadResponse,
  Tag,
} from "models/api/response.types";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import {
  IconButton,
  Skeleton,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  Box,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useTags } from "api/tagService";
import { useSelector } from "react-redux";
import displayTextWithMath from "utils/displayTextWithMath";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import clsx from "clsx";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { MoreVert, StarOutlineOutlined } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import { useDocumentCollections } from "api/documentCollectionService";
import { useDocuments } from "api/documentService";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import {
  SearchType,
  selectIsSearch,
  selectSearchQuery,
  selectSearchTypeQuery,
} from "store/features/browser/slice";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  getDocumentStatus,
  isTable as isTableType,
  isStub as isStubType,
} from "utils/documentStatus";
import { bibtexEntry2html } from "utils/bibtex";
import detexify, { applyDocumentDetexification } from "utils/detexify";
import { isGuest } from "models/components/Permissions.models";
import { DocumentsContext } from "pages/Documents";
import { useUsers } from "api/userService";
import TagsListStandalone from "components/Tags/tagsCellRenderer";
import { useDrag } from "react-dnd";
import { DragTypes } from "utils/dragTypes";
import { getEmptyImage } from "react-dnd-html5-backend";
import { companyFeatures } from "company-config";
import { RootContainer } from "./ListItem-styles";
import {
  searchTextConverter,
  setAuthorsData,
} from "../../../../utils/documentListHelpers";

const DocumentListItem: React.FC<{
  document: DocumentReadResponse;
  selectedDocuments: DocumentReadResponse[];
  setTableDocumentToUpdate: (doc: DocumentReadResponse) => void;
  setSelectedDocuments: (documents: DocumentReadResponse[]) => void;
  multiSelectDocuments: (id: number, e: any) => void;
  style?: CSSProperties;
}> = ({
  document: propDocument,
  setTableDocumentToUpdate,
  selectedDocuments,
  setSelectedDocuments,
  multiSelectDocuments,
  style,
}) => {
  const theme = useTheme();
  const {
    documentToShow,
    setDocumentToShow,
    triggerSpecialInputFile,
    setDraggableDocumentId,
  } = useContext(DocumentsContext);
  const user = useSelector(selectUser);
  const isSearch = useSelector(selectIsSearch);
  const searchQuery = useSelector(selectSearchQuery);
  const searchTypeQuery = useSelector(selectSearchTypeQuery);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { users } = useUsers(currentOrganizationId);
  const { getCachedTagById } = useTags(currentOrganizationId);
  const { getCachedCollectionByName, updateCollectionMutation } =
    useDocumentCollections(currentOrganizationId);
  const { deleteMultipleDocumentsMutation, updateDocumentMutation } =
    useDocuments(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const document = applyDocumentDetexification(propDocument);
  const [favoriteCollection] = getCachedCollectionByName("Favorites");
  const isFavorite = favoriteCollection?.document_ids.includes(document.id);
  const [tagsCount, setTagsCount] = useState(5);
  const summaryRef = useRef<HTMLDivElement>(null);
  const [enablePropogation, setEnablePropogation] = useState(false);
  const { logAction } = useTelemetry();

  const documentIsInTrash = !!document.is_trash;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<Element | null>(null);
  const [searchSnippet, setSearchSnippet] = useState<string>("");
  const menuOpen = Boolean(menuAnchorEl);
  const isDocViewerEnabled =
    !isGuest(currentUserRole) ||
    companyFeatures.app.guests.enableDocumentViewer;
  const enableDocumentActions =
    companyFeatures.app.guests.enableDocumentActions ||
    !isGuest(currentUserRole);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (summaryRef.current?.offsetWidth) {
      const width = summaryRef.current?.offsetWidth;
      if (width > 850) {
        setTagsCount(5);
      } else if (width > 720) {
        setTagsCount(4);
      } else if (width > 560) {
        setTagsCount(2);
      } else {
        setTagsCount(1);
      }
    }
  }, [summaryRef.current?.offsetWidth]);

  const isSelected = selectedDocuments.some((doc) => doc.id === document.id);

  const { isNew, isStub, isTable, defaultTitle, defaultDescription } =
    useMemo(() => {
      const readBy = document?.ui?.read_by;
      const is_new = readBy ? !new Set(readBy).has(user?.id) : false;

      return {
        isNew: is_new,
        isStub: isStubType(document),
        isTable: isTableType(document),
        defaultTitle:
          document?.ui?.show_file_name?.includes(user?.id) && document.filename
            ? document.filename
            : document?.detexiFiedMeta?.title ||
              document?.meta?.title ||
              document.filename ||
              "",
        defaultDescription:
          document?.detexiFiedMeta?.abstract || document?.meta?.abstract || "",
      };
    }, [document]);

  const displayedTitle =
    !isSearch || searchTypeQuery === SearchType.semantic
      ? defaultTitle
      : searchTextConverter(searchQuery, defaultTitle);

  // props for document that you can drop
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: DragTypes.DOCUMENT,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (document?.snippet && document.snippet !== searchSnippet) {
      let shownSnippet = document.snippet;
      if (document.snippet !== "") {
        const emIndex = document.snippet.indexOf("<em>");
        if (emIndex > 50) {
          shownSnippet = `...${document.snippet.slice(emIndex - 50)}`;
        }
      }
      setSearchSnippet(shownSnippet);
    }
  }, [document]);

  // DND react didn't pass correct document
  // workaround pass documentId to state
  // TODO: find why DND works incorrect
  useEffect(() => {
    if (isDragging) {
      setDraggableDocumentId(document.id);
    }
  }, [isDragging]);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const openDocumentViewer = (method: string) => {
    const newPageUrl = `/document/${document.id}/view?organizationId=${document.organization_id}`;
    logAction(telemetryAction.open_document_viewer, {
      document_id: document.id,
      method,
    });
    window.open(newPageUrl, "_blank");
  };

  const failedDocumentListOptions = [
    {
      icon: <UploadOutlinedIcon fontSize="small" color="action" />,
      label: "Re-Upload",
      onClick: () => {
        triggerSpecialInputFile(document.id);
      },
    },
    {
      icon: <DeleteForeverOutlinedIcon fontSize="small" color="action" />,
      label: "Delete",
      onClick: () => {
        deleteMultipleDocumentsMutation.mutate([document.id]);
      },
    },
  ];

  const renderCompactAuthors = () => {
    return (
      <>
        {!isSearch || searchTypeQuery === SearchType.semantic ? (
          <Typography variant="body2">{authors.join(", ")}</Typography>
        ) : (
          <div
            className="description-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `${searchTextConverter(searchQuery, authors.join(", "))}`,
            }}
          />
        )}
      </>
    );
  };

  const markAsRead = () => {
    updateDocumentMutation.mutate({
      id: document.id,
      payload: {
        ui: {
          ...document.ui,
          read_by: [...document.ui.read_by, user?.id],
        },
      },
    });
  };

  const addDocumentToCollection = (collectionName: string) => {
    let collection: DocumentCollection | undefined;
    let documentSpecialCase;
    if (collectionName === "Favorites") {
      collection = favoriteCollection;
      documentSpecialCase = isFavorite;
    }
    if (collection) {
      if (!documentSpecialCase) {
        const newCollectionIds = [...collection.document_ids, document.id];
        updateCollectionMutation.mutate({
          collections: [
            {
              id: collection.id,
              document_ids: newCollectionIds,
            },
          ],
        });
      } else {
        const newCollectionIds = collection.document_ids.filter(
          (id) => id !== document?.id
        );
        updateCollectionMutation.mutate({
          collections: [
            {
              id: collection.id,
              document_ids: newCollectionIds,
            },
          ],
        });
      }
    }
  };

  const authors = setAuthorsData(
    document?.detexiFiedMeta?.author || document?.meta?.author || ""
  );
  const docStatus = getDocumentStatus(document);

  const renderReferenceInfo = () => {
    const formatted = bibtexEntry2html(
      document.detexiFiedMeta
        ? {
            ...document.detexiFiedMeta,
            author: Array.isArray(document?.detexiFiedMeta?.author)
              ? document.detexiFiedMeta.author.join(" and ")
              : `${document?.detexiFiedMeta?.author}`,
          }
        : {
            ...document.meta,
            author: Array.isArray(document?.meta?.author)
              ? document.meta.author.join(" and ")
              : `${document?.meta?.author}`,
          },
      !isSearch || searchTypeQuery === SearchType.semantic
    );
    if (
      formatted.remainder.includes("()") ||
      formatted.remainder.includes("undefined")
    ) {
      return "";
    }
    return !isSearch || searchTypeQuery === SearchType.semantic
      ? formatted.remainder
      : searchTextConverter(searchQuery, formatted.remainder || "");
  };

  // when doc is in progress, return skeleton
  const renderLoadingOverlay = () => {
    const { status } = document;
    const ocrSteps = ["Running OCR", "OCR Complete"];
    const fileExtractSteps = [
      "Extracting data",
      "Extraction completed",
      "Document Processed",
    ];
    const filexExtractActiveStep =
      !status.rasterization || status.rasterization < 2
        ? 1
        : !status.grobid_fulltext || status.grobid_fulltext < 2
        ? 2
        : 2;
    const steps = docStatus.ocr_in_progress ? ocrSteps : fileExtractSteps;
    const activeLabel = docStatus.ocr_in_progress ? 1 : filexExtractActiveStep;

    return (
      <Box className="stepper-loader">
        <Stepper activeStep={activeLabel} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  };

  return (
    <Box style={style}>
      <RootContainer
        className={clsx("list-view-document", {
          "selected-root": isSelected || documentToShow?.id === document.id,
        })}
        isnew={isNew ? "true" : undefined}
        loading={docStatus.in_progress ? "true" : undefined}
        failed={docStatus.failed ? "true" : undefined}
        istrash={documentIsInTrash ? "true" : undefined}
        isguest={isGuest(currentUserRole) ? "true" : undefined}
        docviewerdisabled={!isDocViewerEnabled ? "true" : undefined}
        isfulltextsearch={
          isSearch && searchTypeQuery === SearchType.fulltext
            ? "true"
            : undefined
        }
        onDoubleClick={() => {
          if (!enablePropogation) {
            if (docStatus.can_be_viewed && isDocViewerEnabled) {
              openDocumentViewer("double_click_document");
            } else if (isTable) {
              setTableDocumentToUpdate(document);
            }
          }
        }}
        onClick={(e) => {
          if (
            !enablePropogation &&
            !docStatus.in_progress &&
            !docStatus.failed
          ) {
            if (
              (e.shiftKey || e.ctrlKey || e.metaKey) &&
              enableDocumentActions
            ) {
              window?.getSelection()?.removeAllRanges();
              if (selectedDocuments.length === 0) {
                setSelectedDocuments([document]);
              } else {
                multiSelectDocuments(document.id, e);
              }
            } else if (!documentToShow || documentToShow.id !== document.id) {
              setDocumentToShow(document);
              setSelectedDocuments([document]);
              if (isNew) {
                markAsRead();
              }
            }
          }
        }}
        ref={
          documentIsInTrash ||
          docStatus.in_progress ||
          docStatus.failed ||
          isGuest(currentUserRole)
            ? null
            : drag
        }
      >
        {docStatus.in_progress && renderLoadingOverlay()}
        {enableDocumentActions && (
          <Checkbox
            className="document-checkbox"
            color="primary"
            checked={isSelected}
            disabled={docStatus.in_progress || docStatus.failed}
            onMouseEnter={() => {
              setEnablePropogation(true);
            }}
            onMouseLeave={() => {
              setEnablePropogation(false);
            }}
            onChange={(e) => {
              e.stopPropagation();
              if (isSelected) {
                setSelectedDocuments(
                  selectedDocuments.filter((doc) => doc.id !== document.id)
                );
              } else {
                setSelectedDocuments([...selectedDocuments, document]);
              }
            }}
            size="small"
          />
        )}
        {!documentIsInTrash && (
          <Tooltip
            enterDelay={500}
            placement="top"
            title={isFavorite ? "Remove from favorites" : "Add to favorites"}
          >
            <IconButton
              className="favorites"
              size="medium"
              disabled={docStatus.in_progress || docStatus.failed}
              onMouseEnter={() => {
                setEnablePropogation(true);
              }}
              onMouseLeave={() => {
                setEnablePropogation(false);
              }}
              onClick={() => {
                logAction(telemetryAction.DocumentListItemClickFavorites, {});
                addDocumentToCollection("Favorites");
              }}
            >
              {isFavorite ? (
                <StarIcon fontSize="small" style={{ fill: "#ff9800" }} />
              ) : (
                <StarOutlineOutlined
                  fontSize="small"
                  style={{ fill: "#ff980070" }}
                />
              )}
            </IconButton>
          </Tooltip>
        )}
        <Box
          className={clsx("doc-type-icon", {
            visible: !isStub,
          })}
        >
          {isTable ? (
            <TableChartOutlinedIcon fontSize="small" color="action" />
          ) : (
            <AttachFileIcon fontSize="small" color="action" />
          )}
          {isTable && docStatus.ready && !defaultDescription && (
            <Tooltip
              enterDelay={500}
              placement="bottom"
              title="This table is missing descriptions. 
                The AI relies on the description to understand the nature of the information within the table. 
                Please provide a detailed description if possible."
            >
              <AnnouncementOutlinedIcon
                style={{
                  color: theme.palette.error.dark,
                  width: "1.25rem",
                  height: "1.25rem",
                }}
              />
            </Tooltip>
          )}
        </Box>
        {!docStatus.failed ? (
          <>
            <Box className="summary" ref={summaryRef}>
              <Box className="title-wrapper">
                {!isSearch ? (
                  <Typography
                    variant="body1"
                    className={clsx("header-text", {
                      document: !isStub && !isTable,
                      table: isTable,
                    })}
                    onMouseEnter={() => {
                      if (docStatus.can_be_viewed) {
                        setEnablePropogation(true);
                      }
                    }}
                    onMouseLeave={() => {
                      setEnablePropogation(false);
                    }}
                    onClick={(e) => {
                      if (docStatus.can_be_viewed && isDocViewerEnabled) {
                        e.stopPropagation();
                        openDocumentViewer("double_click_document");
                      } else if (isTable) {
                        e.stopPropagation();
                        setTableDocumentToUpdate(document);
                      }
                    }}
                  >
                    {displayTextWithMath(displayedTitle)}
                  </Typography>
                ) : (
                  <Box
                    className={clsx("header-text", {
                      document: !isStub && !isTable,
                      table: isTable,
                    })}
                    onMouseEnter={() => {
                      if (docStatus.can_be_viewed) {
                        setEnablePropogation(true);
                      }
                    }}
                    onMouseLeave={() => {
                      setEnablePropogation(false);
                    }}
                    onClick={(e) => {
                      if (docStatus.can_be_viewed && isDocViewerEnabled) {
                        e.stopPropagation();
                        openDocumentViewer("double_click_document");
                      } else if (isTable) {
                        e.stopPropagation();
                        setTableDocumentToUpdate(document);
                      }
                    }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: displayedTitle,
                    }}
                  />
                )}
                {!isTable && (
                  <>
                    {docStatus.in_progress ? (
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "14px",
                          minWidth: "150px",
                          marginLeft: "auto",
                        }}
                      />
                    ) : (
                      <Box
                        className="compact-document-data"
                        dangerouslySetInnerHTML={{
                          __html: detexify(renderReferenceInfo(), false),
                        }}
                      />
                    )}
                  </>
                )}
                {document?.meta?.text_character_count > 1000000 && (
                  <Tooltip
                    placement="top"
                    enterDelay={500}
                    title="AI performance may be degraded for documents with more than 1,000,000 characters. Please consider splitting this into smaller sections if possible."
                    sx={{
                      flex: 0,
                    }}
                  >
                    <img
                      src="/img/file-alert.svg"
                      alt="file alert"
                      className="file-alert"
                    />
                  </Tooltip>
                )}
              </Box>
              <Box className="compact-info">
                {docStatus.in_progress ? (
                  <>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "14px", flex: 1 }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "14px", flex: 0.3 }}
                    />
                  </>
                ) : (
                  <>
                    {isTable ? (
                      <Box className="table-description">
                        {defaultDescription && (
                          <Typography variant="body2" color="textSecondary">
                            {defaultDescription}
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      <>
                        {!isSearch ||
                        searchTypeQuery !== SearchType.fulltext ? (
                          <Box className="authors">
                            {authors.length > 0 ? (
                              renderCompactAuthors()
                            ) : (
                              <Typography variant="body2" fontStyle="italic">
                                No authors
                              </Typography>
                            )}
                          </Box>
                        ) : (
                          <Box className="search-snippet">
                            <Typography
                              variant="body2"
                              dangerouslySetInnerHTML={{
                                __html:
                                  document?.snippet && document.snippet !== ""
                                    ? searchSnippet
                                    : "Specifically matched text is not available.",
                              }}
                            />
                          </Box>
                        )}
                      </>
                    )}
                    <Box className="tags-wrapper">
                      {document.tag_ids.length > 0 ? (
                        <TagsListStandalone
                          tags={document.tag_ids.reduce((arr: Tag[], id) => {
                            const [tag] = getCachedTagById(id);
                            if (tag) arr.push(tag);
                            return arr;
                          }, [])}
                          tagsCount={tagsCount}
                          onEnter={() => {
                            setEnablePropogation(true);
                          }}
                          onLeave={() => {
                            setEnablePropogation(false);
                          }}
                          isUserPermitted={!isGuest(currentUserRole)}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          className="tags"
                          color="textSecondary"
                          fontStyle="italic"
                        >
                          No tags
                        </Typography>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <div className="summary">
            <div className="title-wrapper">
              <Typography
                variant="body1"
                className="header-text"
                color="text.secondary"
              >
                {displayTextWithMath(defaultTitle)}
              </Typography>
              {!isGuest(currentUserRole) && (
                <div className="action-container">
                  <IconButton
                    size="small"
                    sx={{
                      opacity: "1 !important",
                      ...(menuOpen && {
                        visibility: "visible !important",
                      }),
                    }}
                    onClick={(e) => {
                      handleMenuClick(e);
                    }}
                  >
                    <MoreVert fontSize="small" color="action" />
                  </IconButton>
                </div>
              )}
            </div>
            <Typography variant="body2" className="description-text error">
              Document processing failed. You can either try to re-upload this
              reference or delete it from the library.
            </Typography>
          </div>
        )}
        {docStatus.failed && (
          <Menu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            {failedDocumentListOptions.map((menu: any) => (
              <MenuItem
                key={menu.label}
                onClick={(e) => {
                  if (menu.onClick) {
                    menu.onClick(e);
                  }
                  handleMenuClose();
                }}
              >
                <ListItemIcon className="menu-icon">{menu.icon}</ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">{menu.label}</Typography>
                </ListItemText>
              </MenuItem>
            ))}
          </Menu>
        )}
      </RootContainer>
    </Box>
  );
};
export default DocumentListItem;
