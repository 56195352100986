export type AILanguage =
  | "English (US)"
  | "English (UK)"
  | "Chinese (Simplified)"
  | "Chinese (Traditional)"
  | "Korean"
  | "Japanese"
  | "German"
  | "French"
  | "Spanish"
  | "Italian"
  | "Ukrainian"
  | "Russian"
  | "Slovak"
  | "Czech"
  | "Polish";

export const AILanguages: AILanguage[] = [
  "English (US)",
  "English (UK)",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Korean",
  "Japanese",
  "German",
  "French",
  "Spanish",
  "Italian",
  "Ukrainian",
  "Russian",
  "Slovak",
  "Czech",
  "Polish",
];

export const AiLanguagesShortcuts: { [key: string]: string } = {
  "English (US)": "US",
  "English (UK)": "UK",
  "Chinese (Simplified)": "简体中文",
  "Chinese (Traditional)": "繁體中文",
  Korean: "한국어",
  Japanese: "日本語",
  German: "DE",
  French: "FR",
  Spanish: "ES",
  Italian: "IT",
  Ukrainian: "УK",
  Russian: "PУ",
  Slovak: "SK",
  Czech: "CS",
  Polish: "PL",
};

export type SelectedTextAiOperations =
  | "summarize"
  | "paraphrase"
  | "translate"
  | "explain"
  | "suggest"
  | "identify";

export const getSelectedTextAiOperationDescription = (
  action: SelectedTextAiOperations,
  language: AILanguage,
  text: string
) => {
  if (action === "summarize") {
    return `Summarize the following text: ${text}`;
  }
  if (action === "paraphrase") {
    return `Paraphrase the following text: ${text}`;
  }
  if (action === "translate") {
    return `Translate the following text into ${language}: ${text}`;
  }
  if (action === "explain") {
    return `Explain the following text using simpler language: ${text}`;
  }
  if (action === "suggest") {
    return `Suggest search terms based on the following text: ${text}`;
  }
  return `Identify key points from the following text: ${text}`;
};

export const terminal_status_names = [
  "success",
  "failure",
  "revoked",
  "rejected",
  "dep_failure",
  "None",
];

export const maxAmountOfCharacters = 400;
